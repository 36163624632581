<template>
  <div id="header" class="container-fluid p-4 w-100 position-absolute">
      <div id="navigation" class="container-fluid p-4 bg-semi-transparent shadow-sm fixed-top transition-750">
          <div class="container d-flex justify-content-between">
              <a href="/#home"><img id="logo" :src="logo" alt="Logistics-Logo" /></a>

              <nav id="navbar" class="navbar">
                  <ul>
                      <li><a class="nav-link scrollto black-white" href="/#home">HOME</a></li>
                      <li class="dropdown"><a href="/#featured-services" class="black-white"><span>COMPANY</span> <i class="bi bi-chevron-down"></i></a>
                          <ul>
                              <li><a href="https://www.group.tatsing.com.ph">TAT SING Group</a></li>
                              <li><a href="https://www.global-trading.tatsing.com.ph">TAT SING Global Trading</a></li>
                              <li><a href="https://www.consulting.tatsing.com.ph">TAT SING Consulting Group</a></li>
                              <li><a href="https://www.express.tatsing.com.ph">TAT SING Express</a></li>
                          </ul>
                      </li>
                      <li><a class="nav-link scrollto black-white" href="/#about">ABOUT US</a></li>
                      <li><a class="nav-link scrollto black-white" href="/#services">SERVICES</a></li>
                      <!-- <li><a class="nav-link scrollto black-white" href="/#events">EVENTS</a></li> -->
                      <li><a class="nav-link scrollto black-white" href="/#contact">CONTACT US</a></li>
                  </ul>
                  <i id="hamburger" class="bi bi-list mobile-nav-toggle"></i>
              </nav>
          </div>
      </div>
  </div>

  <teleport to="body">
    <div id="contact" class="vh-100 position-relative">
        <div class="position-absolute h-100 w-100 overflow-hidden">
            <video class="h-100 w-100" style="object-fit: cover; object-position: top; z-index: -10;" autoplay muted playsinline>
                <source src="./assets/videos/logistics.mp4" type="video/mp4">
            </video>
        </div>
        <div class="position-relative h-auto pb-3 contact-background">
            <div class="container">
                <div id="footer-info" class="row text-white">
                    <div class="col-sm-12 col-md-7"
                    data-aos="fade-right"
                    data-aos-offset="100"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in"
                    data-aos-mirror="true"
                    data-aos-once="true">
                        <div class="position-relative w-100 d-flex flex-column mt-5">
                            <span class="fs-3 fw-bold text-uppercase px-1 text-theme">Contact Us</span>
                            <span class="position-absolute h-100 border-bottom border-5 left-0 my-1 border-secondary-theme underline"></span>
                        </div>
                        <div>
                            <h1 class="fw-bold fs-1 mb-0 mt-4">Start you business with us.</h1>
                            <span class="fw-bold fs-1">Dial us:</span><span class="text-theme fw-bold fs-1"> +639 123 456 7890</span>
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Saepe laboriosam fugit temporibus corporis ex recusandae fuga sed ad quidem, accusamus consequatur repellendus, sit aperiam nemo.</p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 ms-auto" style="z-index: 10;">
                        <div id="message-card" class="bg-white shadow mx-auto py-3 text-dark" 
                            data-aos="fade-down"
                            data-aos-offset="100"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in"
                            data-aos-mirror="true"
                            data-aos-once="true">
                            <h2 class="text-center fs-4 mb-0">Message Us</h2>
                            <h4 class="text-center my-3"
                            :class="{'text-success': !message.includes('Yikes'), 'text-danger': message.includes('Yikes')}"
                            v-show="message">
                              {{ message }}
                            </h4>
                            <form @submit.prevent="send_mail">
                                <div class="px-3">
                                    <div class="card-body d-flex flex-column gap-3">
                                        <input v-model="mail.full_name" type="text" class="w-100 border-0 border-bottom py-2" placeholder="Full Name" required>
                                        <div class="text-danger" v-show="Object.values(errors.full_name).length">
                                            <ul v-for="error in errors.full_name" :key="error">
                                                <li>
                                                    {{ error }}
                                                </li>
                                            </ul>
                                        </div>
                                        <input v-model="mail.email" type="email" class="w-100 border-0 border-bottom py-2" placeholder="E-mail" required>
                                        <div class="text-danger" v-show="Object.values(errors.email).length">
                                            <ul v-for="error in errors.email" :key="error">
                                                <li>
                                                    {{ error }}
                                                </li>
                                            </ul>
                                        </div>
                                        <input v-model="mail.phone" type="number" class="w-100 border-0 border-bottom py-2" placeholder="Contact No." required>
                                        <div class="text-danger" v-show="Object.values(errors.phone).length">
                                            <ul v-for="error in errors.phone" :key="error">
                                                <li>
                                                    {{ error }}
                                                </li>
                                            </ul>
                                        </div>
                                        <textarea v-model="mail.mesg" class="w-100 border-0 border-bottom py-2" placeholder="Message" rows="1" required></textarea>
                                        <div class="text-danger" v-show="Object.values(errors.mesg).length">
                                            <ul v-for="error in errors.mesg" :key="error">
                                                <li>
                                                    {{ error }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="ms-auto mt-3 d-flex">
                                    <div class="email-loader me-2" v-show="$store.state.isLoading"></div>
                                    <button type="submit" class="rounded-l-sm p-2 d-flex align-items-center gap-2 text-white bg-dark border-0 ms-auto">Send Message <img src="././assets/img/icons/arrow-right.png" alt="arrow-right"></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="h-50 bg-gray position-relative">
            <footer id="footer" class="bg-dark">
                <div class="footer-top bg-dark">
                  <div class="container">
                    <div class="row">
            
                      <div class="col-lg-3 col-md-6 footer-contact">
                        <img :src="logo_w" alt="logo_footer" style="width:95%;">
                        <p class="mt-4">
                            10/F Unit 1019 <br>
                            State Center Building, No. 333<br>
                            Juan Luna St., Binondo, Manila City <br>
                        </p>
            
                        <div class="footer-links mt-md-5">
                          <div class="social-links mt-3">
                            <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                            <a href="https://www.facebook.com/tatsinglogistics.ph" class="facebook"><i class="bx bxl-facebook"></i></a>
                            <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                            <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                            <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                          </div>
                        </div>
                      </div>
            
                      <div class="col-lg-3 col-md-6 footer-links">
                        <h4>Useful Links</h4>
                        <ul>
                          <li><i class="bx bx-chevron-right"></i> <a href="/#home">Home</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="/#about">About us</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="/#services">Services</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="/#events">Events</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="/#contact">Contact Us</a></li>
                        </ul>
                      </div>
            
                      <div class="col-lg-3 col-md-6 footer-links">
                        <h4>Our Company</h4>
                        <ul>
                          <li><i class="bx bx-chevron-right"></i> <a href="#home">TAT SING INTERNATIONAL LOGISTICS</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="https://www.global-trading.tatsing.com.ph">TAT SING GLOBAL TRADING</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="https://www.consulting.tatsing.com.ph">TAT SING CONSULTING GROUP</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="https://www.express.tatsing.com.ph">TAT SING EXPRESS</a></li>
                          <li><i class="bx bx-chevron-right"></i> <a href="https://www.group.tatsing.com.ph">TAT SING GROUP</a></li>
                        </ul>
                      </div>
            
                      <div class="col-lg-3 col-md-6 footer-links">
                        <h4>Contact Info</h4>
                        <p class="text-white">
                          <img src="./assets/img/icons/email.png" alt="email icon" width="30" class="p-1"/>
                          info.tatsing.com.ph
                        </p>
            
                        <p class="text-white">
                          <img src="./assets/img/icons/telephone.png" alt="telephone icon" width="30" class="p-1">
                            (02) 8252 1221 <br/><span style="padding-left: 2.1rem;">(02) 8452 2224</span>
                          </p>
            
                          <p class="text-white">
                            <img src="./assets/img/icons/location.png" alt="location icon" width="30" class="p-1"/>
                           10/F Unit 1019 State Center <br>
                           <span style="padding-left: 2.1rem;">Building, No. 333 Juan Luna St.,</span> 
                          <br><span style="padding-left: 2.1rem;">Binondo, Manila City</span>
                          </p>
                      </div>
            
                    </div>
                  </div>
                </div>
            
                <div class="container py-4">
                    <div id="rights" class="copyright text-muted">
                        <!-- js date here -->
                    </div>
                </div>
              </footer>
        </div>
    </div>
    
    <div id="preloader"></div>
    <a href="#" class="back-to-top mx-3 d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
  </teleport>
  <router-view/>
</template>

<style>
.email-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
}

.email-loader:before {
  content: "";
  position: absolute;
  top: calc(50% - 0px);
  left: calc(50% - 30px);
  border: 6px solid rgb(0, 0, 0, 0.2);
  border-top-color: #6E111D;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
const logo = require('./assets/img/TAT-SING-Logistics.png')
const logo_w = require('./assets/img/TAT-SING-Logistics(white-text).png')

export default {
  data() {
    return {
      logo,
      logo_w,
      mail: {
          full_name: '',
          email: '',
          phone: '',
          mesg: '',
      },
      message: '',
      errors: {
          full_name: {},
          email: {},
          phone: {},
          mesg: {},
      },
      isLoading: false
    }
  },
  mounted() {
    if(!localStorage.getItem('logist-visited')) this.$store.dispatch('is_visiting')

    /**
     * 
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      } else {
        return document.querySelector(el)
      }
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all)
      if (selectEl) {
        if (all) {
          selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
          selectEl.addEventListener(type, listener)
        }
      }
    }

    /**
     * Easy on scroll event listener 
     */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener)
    }

    let navbarlinks = select('#navbar .scrollto', true)
      const navbarlinksActive = () => {
        let position = window.scrollY + 200
        navbarlinks.forEach(navbarlink => {
          if (!navbarlink.hash) return
          let section = select(navbarlink.hash)
          if (!section) return
          if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
            navbarlink.classList.add('active')
          } else {
            navbarlink.classList.remove('active')
          }
      })
    }
    window.addEventListener('load', navbarlinksActive)
    onscroll(document, navbarlinksActive)

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
      let header = select('#header')
      let offset = header.offsetHeight

      if (!header.classList.contains('header-scrolled')) {
        offset -= 16
      }

      let elementPos = select(el).offsetTop
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
      })
    }

    /**
     * Back to top button
     */
    let backtotop = select('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      onscroll(document, toggleBacktotop)
    }

    /**
     * Preloader
     */
    let preloader = select('#preloader');
    if (preloader) {
      window.addEventListener('load', () => {
        preloader.remove()
      });
    }

    /**
     * Mobile nav toggle
     */
    on('click', '.mobile-nav-toggle', function() {
      select('#navbar').classList.toggle('navbar-mobile')
      this.classList.toggle('bi-list')
      this.classList.toggle('bi-x')
      document.getElementById("hamburger").classList.toggle('text-white');

      for(var i = 0; i < anchors.length; i++) {
        anchors[i].style.color = "black";
      }
    })

    /**
     * Mobile nav dropdowns activate
     */
    on('click', '.navbar .dropdown > a', function(e) {
      if (select('#navbar').classList.contains('navbar-mobile')) {
        e.preventDefault()
        this.nextElementSibling.classList.toggle('dropdown-active')
      }
    }, true)

    /**
     * Scroll with ofset on links with a class name .scrollto
     */
    on('click', '.scrollto', function(e) {
      if (select(this.hash)) {
        e.preventDefault()

        let navbar = select('#navbar')
        if (navbar.classList.contains('navbar-mobile')) {
          navbar.classList.remove('navbar-mobile')
          let navbarToggle = select('.mobile-nav-toggle')
          navbarToggle.classList.toggle('bi-list')
          navbarToggle.classList.toggle('bi-x')
        }
        scrollto(this.hash)
      }
    }, true)

    //         //
    // Custom //
    //       //
    document.getElementById("navigation").classList.toggle('bg-semi-transparent', window.pageYOffset < 100);
    document.getElementById("navigation").classList.toggle('bg-dark', window.pageYOffset > 100);

    
    var anchors = document.getElementById("navbar").getElementsByClassName("black-white");
    

    if(window.pageYOffset > 100) {
      document.getElementById("logo").src = logo_w;
      for(var i = 0; i < anchors.length; i++) {
        anchors[i].style.color = "white";
      }
      document.getElementById("hamburger").style.color = "white";
    }
    else {
      document.getElementById("logo").src = logo;
      for(var ab = 0; ab < anchors.length; ab++) {
        anchors[ab].style.color = "black";
      }
      document.getElementById("hamburger").style.color = "black";
    }

    window.onscroll = function() {
      document.getElementById("navigation").classList.toggle('bg-semi-transparent', window.pageYOffset < 100);
      document.getElementById("navigation").classList.toggle('navbar-light', window.pageYOffset < 100);
      document.getElementById("navigation").classList.toggle('bg-dark', window.pageYOffset > 100);
      document.getElementById("navigation").classList.toggle('navbar-dark', window.pageYOffset > 100);
      
      var anchors = document.getElementById("navbar").getElementsByClassName("black-white");

      if(window.pageYOffset > 100) {
        document.getElementById("logo").src = logo_w;
        for(var i = 0; i < anchors.length; i++) {
          anchors[i].style.color = "white";
        }
        document.getElementById("hamburger").style.color = "white";

        if(document.getElementsByClassName("navbar-mobile")[0]) {
          for(var a = 0; a < anchors.length; a++) {
            anchors[a].style.color = "black";
          }
          document.getElementById("hamburger").style.color = "black";
        }
      }
      else {
          document.getElementById("logo").src = logo;
          for(var aw = 0; aw < anchors.length; aw++) {
            anchors[aw].style.color = "black";
          }
          document.getElementById("hamburger").style.color = "black";
      }
    }


    var rights = document.getElementById("rights");
    let date =  new Date().getFullYear();

    rights.innerHTML = "Copyright &copy;" + date + ". All Rights Reserved";
  },
  methods: {
    async send_mail() {
      await this.$store.dispatch('send', this.mail).then(() => {
        if(Object.values(this.$store.state.errors).length) Object.assign(this.errors, this.$store.state.errors)
        this.message = this.$store.state.message
        if(this.message.includes('Yikes')) return
        else {
            this.mail = {
            full_name: '',
            email: '',
            phone: '',
            mesg: '',
          }
          this.errors = {
            full_name: {},
            email: {},
            phone: {},
            mesg: {},
          }
        }
        setTimeout(() => this.message = '', 2000)
      })
    }
  }
}
</script>