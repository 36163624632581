<template>
    <div>
        <div id="home" class="logistic-background position-relative">
            <div class="position-absolute w-100 overflow-hidden" style="height: 95%">
                <video class="h-100 w-100" style="object-fit: cover; object-position: center;" autoplay muted loop>
                    <source src="../assets/videos/logistics.mp4" type="video/mp4">
                </video>
            </div>
            <!-- Home Page -->
            <div id="first-container" class="container mx-auto row overflow-hid" style="padding-top: 8rem;">
                <div id="TatSingLogistics" class="mt-5 col-sm-12 col-md-6 h-100">
                    <div class="position-relative h-100" data-aos="zoom-out-right" data-aos-duration="1000">
                        <img class="mt-5" src="../assets/img/TAT-SING-Logistics(white-text).png" alt="TatSingLogistics">

                        <p class="fs-2 lh-sm my-5 text-white">Your <strong class="text-theme">navigator</strong> in the <br> world of trade</p>

                        <a href="/#about" class="text-decoration-none"><button type="button" class="bg-dark p-3 rounded d-flex align-items-center gap-3 text-white">Learn More <img src="../assets/img/arrow-down.png" alt="arrow-down" height="16"></button></a>
                    </div>
                </div>
                <div id="track" class="container col-sm-12 col-md-6 mt-3">
                    <div class="card bg-light rounded shadow-sm m-auto py-4 h-100" style="width: 80%;"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true">
                        <div id="track-search" class="text-center px-5 h-100 justify-content-evenly d-flex flex-column">
                            <div class="px-3 py-1">
                                <img src="../assets/img/Messenger-cuate.png" alt="question-mark" class="w-100 my-3 track-img" style="height: 250px;">
                                <h1 class="fw-bold w-100 mb-0 track-message" style="font-size: 2rem;">Track your Shipment in <br> one click!</h1>
                                <span class="text-gray">Easily check your shipment status now.</span>
                            </div>

                            <div class="px-2 mt-4">
                                <router-link to="/track-shipment"><button type="button" class="bg-theme text-white p-3 rounded my-2 text-center w-100 gap-3 fw-bold">Track my Shipment</button></router-link>
                            </div>
                        </div>
                        <span id="terms-policy" class="mt-3 text-center mb-5" style="font-size: x-small;">Read our <a href="#" class="link-dark fw-bold">Terms and Privacy Policy.</a></span>
                    </div>
                </div>
            </div>
        </div>

        <div id="about" class="vh-100 container p-5" 
        data-aos="fade-right" 
        data-aos-duration="1000" 
        data-aos-offset="100"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true">
            <div class="position-relative w-100 d-flex flex-column mt-5">
                <span class="fs-3 fw-bold text-uppercase px-1 text-theme">About Us</span>
                <span class="position-absolute h-100 border-bottom border-5 left-0 my-1 border-secondary-theme underline"></span>
            </div>
            <div class="row">
                <div class="my-5 col-sm-12 col-md-6">
                    <div class="w-75">
                        <span class="display-5 fw-bold">We are proud to transact since <span class="text-theme">2013</span></span>
                        <img src="../assets/img/Checking-boxes-cuate-(1).png" alt="box-arranging" class="h-auto w-100 my-4">
                    </div>
                </div>
                <div class="my-5 col-sm-12 col-md-6" 
                data-aos="fade-left" 
                data-aos-duration="1500" 
                data-aos-offset="100"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="true" >
                    <h1 class="display-5 fw-bold mt-4">What do you need?</h1>

                    <div id="required" class="d-flex flex-wrap overflow-hidden">
                        <div class="w-50 h-50 p-2"
                        data-aos="fade-left" 
                        data-aos-duration="1500" 
                        data-aos-offset="100"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true">
                            <img src="../assets/img/icons/Outline.png" alt="facility" height="100">
                            <h1 class="fw-bold fs-2 my-2">Pricing and plans</h1>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo architecto incidunt adipisci blanditiis veniam, officiis minus.</p>
                        </div>
                        <div class="w-50 h-50 p-2"
                        data-aos="fade-left" 
                        data-aos-duration="2000" 
                        data-aos-offset="100"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true" >
                            <img src="../assets/img/icons/Weights-table.png" alt="globe" height="100">
                            <h1 class="fw-bold fs-2 my-2">Weights Table</h1>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo architecto incidunt adipisci blanditiis veniam, officiis minus.</p>
                        </div>
                        <div class="w-50 h-50 p-2"
                        data-aos="fade-left" 
                        data-aos-duration="2500" 
                        data-aos-offset="100"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true" >
                            <img src="../assets/img/icons/consulting.png" alt="staff" height="100">
                            <h1 class="fw-bold fs-2 my-2">Our Staff</h1>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo architecto incidunt adipisci blanditiis veniam, officiis minus.</p>
                        </div>
                        <div class="w-50 h-50 p-2"
                        data-aos="fade-left" 
                        data-aos-duration="3000" 
                        data-aos-offset="100"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true">
                            <img src="../assets/img/icons/Track.png" alt="facility" height="100">
                            <h1 class="fw-bold fs-2 my-2">Pricing and plans</h1>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo architecto incidunt adipisci blanditiis veniam, officiis minus.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="services" class="bg-light vh-100">
            <div class="container mt-5 p-5">
                <div class="position-relative w-100 d-flex flex-column" 
                data-aos="fade-down"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="true">
                    <span class="fs-3 fw-bold text-uppercase px-1 text-theme mt-5">Services</span>
                    <span class="position-absolute h-100 border-bottom border-5 left-0 my-1 border-secondary-theme underline"></span>
                </div>

                <h1 class="display-4 fw-bold mt-4 w-50"
                data-aos="fade-down"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="true">See what can we do for you and your business</h1>
                <div class="row">
                    <div class="py-5 col-sm-12 col-md-4"
                        data-aos="zoom-in"
                        data-aos-offset="100"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true">
                        <div class="pe-5">
                            <img src="../assets/img/Free-shipping-cuate.png" alt="delivery-truck" style="width: 100%; height: 150px;">
                            
                            <h1 class="fs-4 my-3 w-75">Custom Brokerage</h1>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur illum ipsam temporibus totam accusantium exercitationem reprehenderit voluptatum quia suscipit qui!</p>
                        </div>
                    </div>
                    <div class="py-5 col-sm-12 col-md-4"
                        data-aos="zoom-in"
                        data-aos-offset="100"
                        data-aos-delay="100"
                        data-aos-duration="1500"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true">
                        <div class="pe-5">
                            <img src="../assets/img/Checking-boxes-cuate.png" alt="boxes" style="width: 100%; height: 150px;">
                            
                            <h1 class="fs-4 my-3 w-75">Warehousing and consolidation</h1>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur illum ipsam temporibus totam accusantium exercitationem reprehenderit voluptatum quia suscipit qui!</p>
                        </div>
                    </div>
                    <div class="py-5 col-sm-12 col-md-4"
                        data-aos="zoom-in"
                        data-aos-offset="100"
                        data-aos-delay="150"
                        data-aos-duration="2000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true">
                        <div class="pe-5">
                            <img src="../assets/img/IFF.png" alt="globe" style="width: 100%; height: 150px; object-fit: scale-down;">
                            <h1 class="fs-4 my-3 w-75">International Frieght Forwarding</h1>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur illum ipsam temporibus totam accusantium exercitationem reprehenderit voluptatum quia suscipit qui!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Home',
}
</script>
