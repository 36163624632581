import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/track-shipment',
    name: 'Track Shipment',
    component: () => import('../views/TrackShipment.vue'),
    meta: { title: 'Track Shipment' }
  },
  {
    path: "/:catchAll(.*)",
    component: () => import('../views/NotFound.vue'),
    meta: { title: 'Page Not Found' }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta && to.meta.title ? 'TAT SING INTL LOGISTIC ' + to.meta.title : 'TAT SING INTL LOGISTIC';
  store.commit('isLoading')
  next();
})

router.afterEach(() => {
  store.commit('isFinishedLoading')
})

export default router
