import { createStore } from 'vuex'
import axios from 'axios'

axios.defaults.baseURL = "https://tsg-api.tatsing.com.ph/api/v1"
// axios.defaults.baseURL = "http://localhost:8000/api/v1"

export default createStore({
  state: {
    logistic_data: {},
    isLoading: false,
    searching: false,
    errors: {},
    message: ''
  },
  mutations: {
    isLoading(state) {
      state.isLoading = true
    },
    isFinishedLoading(state) {
      state.isLoading = false
    }
  },
  actions: {
    async is_visiting() {
      await axios.post('/visit', { company_name: 'International Logistics' }).then(() => {
        localStorage.setItem('logist-visited', true)
      })
    },
    async send({state}, mail_data) {
      state.isLoading = true
      mail_data['company'] = 'International Logistics'
      await axios.post('/send', mail_data).then(() => {
        state.message = 'Sending was Successful'
        state.isLoading = false
      }).catch(error => {
        state.message = 'Yikes! Please try again later';
        if(error.response.status === 422) {
          state.message = 'Yikes! '+error.response.data.message;
          if(error.response.data.errors)
          {
            state.errors = error.response.data.errors
          }
        }
        if(error.response.status === 429) {
          state.message = 'Yikes! Please wait for 5 minutes before sending again.';
        }
        state.isLoading = false
      })
    },
    async getSRN({state}, srn) {
      state.searching = true
      await axios.get('/get-srn', { params: { srn: srn } }).then(response => {
        state.logistic_data = response.data.data[0]
        state.message = ''
      }).catch(error => {
        if(error.response.status == 404) state.message = 'Not Found'
      })
      state.searching = false
    }
  },
  modules: {
  }
})